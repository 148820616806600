import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import {getAuth} from "firebase/auth";

// GAGE Admin
const firebaseConfig = {
    apiKey: "AIzaSyDSfQEDKQl1uIQlgvu7qJ3LGzW6W2ZArto",
    authDomain: "gage-admin.firebaseapp.com",
    projectId: "gage-admin",
    storageBucket: "gage-admin.appspot.com",
    messagingSenderId: "543439972452",
    appId: "1:543439972452:web:59305a7a68a300626e1771"
};
// GAGE NEW DB
// const firebaseConfig = {
//     apiKey: "AIzaSyC6fnB8A8PsnVx9PDo6BJTkSZa50Xvn-UM",
//     authDomain: "gage-admin-new.firebaseapp.com",
//     projectId: "gage-admin-new",
//     storageBucket: "gage-admin-new.appspot.com",
//     messagingSenderId: "1071431395184",
//     appId: "1:1071431395184:web:7ff696fef87d6153e211b5"
// };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
// const admin= firebase.firestore();
const auth = getAuth(app);
// const set =setDoc(app)

export {db, storage, auth}